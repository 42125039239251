<template>
  <v-card>
    <v-card-title>
      <span class="headline">Ir a WhatsApp de {{ name }}</span>
    </v-card-title>
    <v-card-text class="center">
      <v-textarea
        color="primary"
        v-model="textWA"
        outlined
        dense
        rows="2"
        label="Mensaje predeterminado..."
        hide-details="auto"
      ></v-textarea>
      <br />
      <v-btn
        small
        target="_blank"
        :href="infoSend"
        @click="infoW(optionsW[i])"
        v-bind:style="{ 'margin-bottom': '3%' }"
        color="primary"
        v-for="(data, i) in optionsW"
        :key="optionsW[i]"
        class="btn"
      >
        Escribir a la linea WA {{ i + 1 }}
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="$emit('eventosWa', false)"
        >Cerrar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "WhatsApp",
  props: ["optionsW", "name"],

  data() {
    return {
      textWA: "",
      infoSend: "",
    };
  },
  watch: {
    textWA(val) {
      this.infoSend = this.infoW + val;
    },
  },
  mounted() {
    this.textWA = "";
  },
  methods: {
    infoW(data) {
      this.infoW = data;
      this.infoSend = this.infoW + this.textWA;
      window.open(this.infoSend);
    },
  },
};
</script>
<style scoped>
.center {
  text-align: center !important;
}

.btn {
  margin: 5px !important;
}
</style>


